export{}
declare global {
  interface Date {
    roundNextFive(): Date;
    HHmm(): string;
  }
}

Date.prototype.roundNextFive = function(): Date {
  /* round to "date.minute, 0 sec" if minutes end by 0 or 5; round to the next minutes ending by 0 or 5 */
  const fiveMin: number = 300000;
  if (this.getMinutes() % 5 === 0) {
    this.setSeconds(0, 0);
    return this;
  }
  this.setTime(Math.ceil(this.getTime() / fiveMin) * fiveMin);
  return this;
};

Date.prototype.HHmm = function (): string {
  let HH: string = '00';
  let mm: string = '00';
  HH += this.getHours().toString();
  mm += this.getMinutes().toString();
  return HH.slice(-2)+':'+mm.slice(-2);
}
