<nav>
    <mat-toolbar color='primary' class="row-container gap10px ">
      <button mat-icon-button (click)="close()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 style="margin: 0 !important">Menu</h1>
    </mat-toolbar>

    <ng-container *ngFor="let list of menuItems">
      <ng-container *ngIf="role && list.roles.includes(role)">
        <div mat-subheader>{{list.header}}</div>
        <mat-list class="example-list-wrapping">
          <ng-container *ngFor="let item of list.list">
            <mat-list-item *ngIf="item.roles.includes(role) && item.displayOn.includes(screenSize)"
                           [lines]="item.description? '2': '1'"
                           [routerLink]="item.link"
                           (click)="close()">
              <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
              <div matListItemTitle>{{item.name}}</div>
              <div matListItemLine *ngIf="item.description">{{item.description}}</div>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </ng-container>
    </ng-container>
</nav>
