<nav>
  <mat-toolbar [color]="online ? 'primary' : 'warn'" class="row-container space-between">
    <div class="row-container gap10px align-center">
      <button mat-icon-button (click)="openMenu()" *ngIf="role && role != 'anonymous' && role != 'pre-registration'">
        <mat-icon>menu</mat-icon>
      </button>

      <span>{{ shopName.toUpperCase() }}</span>
    </div>
    <div class="row-container gapSm align-center">
      <span>{{ now$ | async | date: "HH:mm" }}</span>

      <button mat-mini-fab
              class="flag-button"
              *ngIf="flag$ | async as flag"
              (click)="changeLang()"
              [style.background-image]="'url(' + flag + ')'"></button>
      <button mat-icon-button (click)="openLoginDialog()" *ngIf="!(flag$ | async)">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-icon *ngIf="!online && !(flag$ | async)">wifi_off</mat-icon>
    </div>
  </mat-toolbar>
</nav>
