import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {FsService} from "@services/fs.service";
import {AuthService} from "@services/auth.service";

@Injectable({
  providedIn: 'root'
})

export class ShopService {
  /** this service listen for shop name change in the url, url without shop Name are registered here */
  public shopId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private roleSub?: Subscription;

  public getSubdomain(): string | null {
    /** get Subdomain ID from url **/
    const hostname: string = window.location.hostname;
    const parts: string[] = hostname.split('.');

    // Check for standard domain structure or local development domains
    if ((parts.length >= 3 && parts[parts.length - 1].length > 2) || (parts.length >= 2 && parts[parts.length - 1] === 'localhost')) {
      return parts[0];
    }

    return null;
  }
  constructor(private router: Router,
              private fsService: FsService,
              private auth: AuthService) {
    /** initialization, if user start navigation in a subdomain **/
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const newSubdomain: string | null = this.getSubdomain();
        if (newSubdomain !== this.shopId$.value) {
          this.shopId$.next(newSubdomain);
          this.onSubdomainChange(newSubdomain).then();
        }
      });

    // Initial detection if the user starts on a subdomain
    const initialSubdomain: string | null = this.getSubdomain();
    if (initialSubdomain) {
      this.shopId$.next(initialSubdomain);
      this.onSubdomainChange(initialSubdomain).then();
    }
  }



  private async onSubdomainChange(subdomain: string | null): Promise<void> {
    /** this function call firestore for that subdomain => mounted Shop in shop$ **/
    try {
      await this.fsService.getShop(subdomain);
      /** if already subscribe to role => unsubscribe **/
      this.roleSub? this.roleSub.unsubscribe(): null;
      this.roleSub = this.subscribeToRole$(subdomain);
    } catch (e: any) {
      console.error(e.message)
    }
  }

  private subscribeToRole$(shopId: string | null): Subscription {
    /** this function is call once, it will react on role change to mounted / unmount fleet$, and todayRents$ **/
    return this.auth.role$.subscribe(async (role) => {
      try{
        switch (role){

          /** reception and platform need to subscribe to fleet and today rents **/
          case 'reception' || 'platform':
            await this.fsService.getFleet(shopId);
            await this.fsService.getRents(shopId);
            break;

          /** admin need only fleet, and one certain component only, maybe calling it in those component is better ? **/
          case 'admin':
            await this.fsService.getFleet(shopId);
            break;

          /** other role do not need those => dismount and stop listening **/
          default:
            await this.fsService.getFleet(null);
            await this.fsService.getRents(null);
            break;
        }
      } catch (e: any){
        console.error("error in shopService => subscribeToRole$");
        console.error(e);
      }
    });
  }
}
