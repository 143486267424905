import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-offline',
  templateUrl: './dialog-offline.component.html',
  styleUrls: ['./dialog-offline.component.scss']
})
export class DialogOfflineComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogOfflineComponent>) { }

  ngOnInit(): void {
  }

}
