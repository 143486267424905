import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "@services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FsService } from "@services/fs.service";
import { Subscription } from "rxjs";
import {Shop} from "@models/shop.model";

// Interface pour typer le formulaire
interface LoginForm {
  email: FormControl<string | null>;
  role: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.scss']
})
export class DialogLoginComponent implements OnInit, OnDestroy {
  public role: "anonymous" | "pre-registration" | "reception" | "platform" | "admin" | null | undefined = this.auth.role$.value;
  public selectedRole: string | null | undefined;
  public shopId: string | null = this.fs.shop$.value?.id || null;
  private subs: Subscription[] = [];

  // Array of roles and emails
  public roles: { role: string, display: string, email: string | null }[] = [];

  constructor(public dialogRef: MatDialogRef<DialogLoginComponent>,
              public fs: FsService,
              public auth: AuthService) { }

  public loginForm: FormGroup<LoginForm> = new FormGroup({
    email: new FormControl<string | null>(null, {
      validators: [Validators.required, Validators.email],
      updateOn: 'blur'
    }),
    role: new FormControl<string | null>(null),
    password: new FormControl<string | null>(null, [Validators.required])
  });

  ngOnInit(): void {
    // Subscribe to role changes
    this.subs.push(
      this.auth.role$.subscribe((role) => {
        this.role = this.selectedRole = role;
        this.loginForm.patchValue({role: role});
      })
    );

    // Subscribe to shopId changes and update form validators accordingly
    this.subs.push(
      this.fs.shop$.subscribe((shop: Shop | null) => {
        if(shop){
          console.log('Shop: ', shop);
          this.shopId = shop.id;
          this.updateFormValidators();
          this.updateRoles();
        }
      })
    );

    // Subscribe to role value changes to enable/disable password field and email field
    let roleControlChange: Subscription | undefined = this.loginForm.get('role')?.valueChanges.subscribe((role) => {
        this.selectedRole = role;
        if (this.selectedRole === 'admin') {
          this.loginForm.get('email')?.setValidators([Validators.required, Validators.email]);
          this.loginForm.get('password')?.enable();
        } else if (this.selectedRole === undefined || this.selectedRole === 'anonymous') {
          this.loginForm.get('password')?.disable();
          this.loginForm.get('email')?.setValidators([Validators.required, Validators.email]);
        } else {
          this.loginForm.get('password')?.enable();
          this.updateFormValidators();
        }
        this.loginForm.get('email')?.updateValueAndValidity();
      });

    roleControlChange? this.subs.push(roleControlChange): console.warn('subscription error');
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private updateFormValidators(): void {
    const emailControl = this.loginForm.get('email');
    const roleControl = this.loginForm.get('role');

    if (this.shopId === '' || this.shopId === null) {
      emailControl?.setValidators([Validators.required, Validators.email]);
      roleControl?.clearValidators();
    } else {
      emailControl?.clearValidators();
      roleControl?.setValidators([Validators.required]);
    }

    emailControl?.updateValueAndValidity({ emitEvent: false });
    roleControl?.updateValueAndValidity({ emitEvent: false });
  }

  private updateRoles(): void {
    if (this.shopId) {
      this.roles = [
        { role: 'pre-registration', display: 'Pré-enregistrement public', email: `pre-registration@${this.shopId}.rentwave.app` },
        { role: 'reception', display: 'Acceuil', email: `reception@${this.shopId}.rentwave.app` },
        { role: 'platform', display: 'Quais', email: `platform@${this.shopId}.rentwave.app` },
        { role: 'admin', display: 'Administrateur', email: null }
      ];
    } else {
      this.roles = [
        { role: 'admin', display: 'Administrateur', email: null }
      ];
    }
  }

  // Method to close the dialog
  public cancel(): void {
    this.dialogRef.close();
  }

  // Method to submit the login form
  public async submit(): Promise<void> {
    const formValues = this.loginForm.value;
    let email = formValues.email;
    const password = formValues.password;

    // Use the email associated with the selected role if it is not 'admin'
    if (this.selectedRole !== 'admin') {
      const selectedRoleData = this.roles.find(role => role.role === this.selectedRole);
      if (selectedRoleData) {
        email = selectedRoleData.email!;
      }
    }

    const result = await this.auth.signInWithEmail(email!, password!);

    if (result.user) {
      console.log(result.user)
      this.dialogRef.close();
    } else {
      this.handleError(result.error);
    }
  }

  // Method to sign out the user
  public logout(): void {
    this.auth.signOut();
    this.dialogRef.close();
  }

  // Method to handle errors from signInWithEmail
  private handleError(errorCode: string): void {
    switch (errorCode) {
      case 'auth/wrong-password':
        this.loginForm.get('password')?.setErrors({ wrongPassword: true });
        break;
      // You can handle other error codes here
      default:
        // Handle default case or unknown error codes
        this.loginForm.get('email')?.setErrors({ unknownError: true });
        this.loginForm.get('password')?.setErrors({ unknownError: true });
        break;
    }
  }
}
