<mat-drawer-container hasBackdrop="true" style="height: 100%; overflow: hidden; background-color: rgba(0, 0, 0, 0)">
  <mat-drawer #drawer mode="over" style="overflow: auto">
    <app-menu (toggleSidenav)="drawer.toggle()"></app-menu>
  </mat-drawer>
  <mat-drawer-content>
    <app-header (toggleSidenav)="drawer.toggle()"></app-header>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>


