<mat-card>
  <div class="column-container gap10px">
    <h1>Rent Wave</h1>
    <h2>Le contenu de cette page sera mise en place dans une prochaine mise à jour.</h2>
    <p>Pour utiliser le logiciel, crée un magasin, ou connecter vous et choisiez le magasin que vous souhaitez gérer</p>
    <a mat-flat-button href="/admin/my-shops">Mes Bases</a>
  </div>
</mat-card>



