export const environment = {
  firebase: {
    apiKey: "AIzaSyBI3AyfAaUIiv9XKDlwba1kfL672FUfqy0",
    authDomain: "rentwave-prod.firebaseapp.com",
    projectId: "rentwave-prod",
    storageBucket: "rentwave-prod.appspot.com",
    messagingSenderId: "662917917544",
    appId: "1:662917917544:web:58cfe2c3b88dc068fa44dd",
    measurementId: "G-LKC981ZR5K"
  },
  production: true,
  useEmulators: false,
};
