import {Component, OnInit} from '@angular/core';
import {HeadManagerService} from "@services/headManager.service";
import {BackgroundImgService} from "@services/background-img.service";
import {ShopService} from "@services/shop.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  constructor(private headManageService: HeadManagerService, private backgroundImgService: BackgroundImgService, private shopService: ShopService) {
  }
  /** warning those services call them self, do no delete **/

  public title: string = '';
  ngOnInit(): void {
    const isPwa: boolean = window.matchMedia('(display-mode: standalone)').matches;
    console.log('application installé: '+isPwa);

    window.onerror = function(message, source, lineno, colno, error) {
      alert(`Error: ${message} at ${source}:${lineno}:${colno}`);
    };
  }
}
