import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { FsService } from '@services/fs.service';
import { StorageService } from '@services/storage.service';
import {Shop} from "@models/shop.model";

@Injectable({
  providedIn: 'root'
})
export class BackgroundImgService {
  private defaultBackgroundPath: string
  private backgroundPath: string
  private inUseBackgroundPath: string
  private renderer: Renderer2;

  constructor(
    private fs: FsService,
    private storageService: StorageService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.defaultBackgroundPath = '/backgrounds/default-background-transparency.webp';
    this.backgroundPath = '';
    this.inUseBackgroundPath = 'initial';



    this.fs.shop$.subscribe(async (shop: Shop | null): Promise<void> => {
      if (shop) {
        this.backgroundPath = `backgrounds/${shop.settings.backgroundImg}`
      } else {
        this.backgroundPath = this.defaultBackgroundPath;
      }

      /** do not fetch and apply if already applied **/
      if(this.backgroundPath != this.inUseBackgroundPath && this.backgroundPath != ''){
        console.log(this.backgroundPath);
        let storageUrl: string = await this.storageService.getFile(this.backgroundPath);
        this.renderer.setStyle(document.body, 'background-image', 'url('+storageUrl+')');
        this.inUseBackgroundPath = this.backgroundPath
      }
    });
  }
}
