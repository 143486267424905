<div>
  <mat-dialog-content>
    <mat-card-title>{{data.title}}</mat-card-title>
    <mat-card-subtitle>{{data.subtitle}}</mat-card-subtitle>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end none">
        <button mat-flat-button (click)="cancel()">ANNULER</button>
        <button mat-flat-button color="warn" (click)="valid()">SUPPRIMER</button>
  </mat-dialog-actions>
  </div>

