import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {Shop} from "@models/shop.model";
import {FsService} from "@services/fs.service";

interface MenuItems {
  header: string,
  roles: string[],
  list: {
    name: string,
    link: string,
    icon: string,
    description: string | undefined,
    roles: string[],
    displayOn: ('smallScreen'| 'largeScreen')[]
  }[]
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit{
  public menuItems: MenuItems[] = [];
  public shopName: string = '';
  private shopId: string | null = null;
  public screenSize: 'smallScreen'| 'largeScreen' = 'largeScreen';
  public role: "anonymous" | "pre-registration" | "reception" | "platform" | "admin" | null | undefined;
  private screenWidth: number;
  private minSize: number = 400;

  constructor(private authService: AuthService,
              private fs: FsService) {
    this.screenWidth = window.innerWidth;
    this.role = this.authService.role$.value;
    this.menuItems = [
        {
          header: 'Accueil',
          roles: ['reception', 'platform'],
          list: [
            {
              name: 'Reservation',
              link: '/reservations',
              icon: 'point_of_sale',
              description: 'Nouvelles fiches de location',
              roles: ['reception'],
              displayOn: ['largeScreen']
            },
            {
              name: 'Pre-enregistrés',
              link: '/pre-registered',
              icon: 'more_time',
              description: 'Liste des clients pre-enregistrer',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Zone d\'accueil',
              link: '/acceuil',
              icon: 'fact_check',
              description: 'Filtrage des clients vers et depuis les quais',
              roles: ['reception', 'platform'],
              displayOn: ['largeScreen']
            },
            {
              name: 'En attente',
              link: '/waiting',
              icon: 'hourglass_top',
              description: 'Liste des clients en attente d\'appel',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Appel en cours',
              link: '/called',
              icon: 'notifications_active',
              description: 'Liste des clients devant se presenter à l\'équipement',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Fin de location',
              link: '/landing',
              icon: 'power_settings_new',
              description: 'liste des locations en attente de fermeture',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'Planning',
              link: '/planning',
              icon: 'event_note',
              description: 'Visualisation du planning du jour',
              roles: ['reception'],
              displayOn: ['smallScreen', "largeScreen"]
            },
          ]
        },

        {
          header: 'Quais',
          roles: ['reception', 'platform'],
          list: [
            {
              name: 'Quais',
              link: '/quai',
              icon: 'fact_check',
              description: 'Zone des départ et retours',
              roles: ['reception', 'platform'],
              displayOn: ['largeScreen']
            },
            {
              name: 'Equipement',
              link: '/boarding',
              icon: 'logout',
              description: 'Zone des départs',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
            {
              name: 'En cours',
              link: '/sailing',
              icon: 'login',
              description: 'Zone des Arrivées',
              roles: ['reception', 'platform'],
              displayOn: ['smallScreen']
            },
          ]
        },

        {
          header: 'Paramètres',
          roles: ['reception'],
          list: [
            {
              name: 'Paramètre fiche de location',
              link: '/parametres',
              icon: 'settings',
              description: 'Paramétrage des nouvelles fiches de location',
              roles: ['reception', 'admin'],
              displayOn: ['smallScreen', 'largeScreen']
            },

            {
              name: 'Gestionnaire de flotte',
              link: '/flotte',
              icon: 'format_list_numbered',
              description: 'Visualisation et modification des équipements proposés',
              roles: ['reception'],
              displayOn: ['smallScreen', 'largeScreen']
            },

            {
              name: 'Tarifs',
              link: '/tarifs',
              icon: 'price_check',
              description: 'Visualisation et paramétrage des tarifs et des durées de location',
              roles: ['reception'],
              displayOn: ['smallScreen', "largeScreen"]
            },

            {
              name: 'Internationalisation',
              link: '/i18n',
              icon: 'g_translate',
              description: 'Gestion des langues public',
              roles: ['reception'],
              displayOn: ['smallScreen', 'largeScreen']
            }
          ]
        },
        {
          header: 'Statistiques',
          roles: ['admin'],
          list: [
            {
              name: 'Statistiques',
              link: '/admin/statistiques',
              icon: 'bar_chart',
              description: 'Statistiques avancées',
              roles: ['admin'],
              displayOn: ['smallScreen', 'largeScreen']
            }
          ]
        }
      ]
  }

  @Output() toggleSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();
  @HostListener('window:resize', ['$event'])
  sizeChange(event: any): void {
    this.screenWidth = event.currentTarget.innerWidth;
    this.isSmall();
  }

  private isSmall(): void {
   this.screenSize = this.screenWidth < this.minSize? 'smallScreen': 'largeScreen';
  }

  public close(): void {
    this.toggleSidenav.emit();
  }


  async ngOnInit(): Promise<void> {
    this.screenWidth = window.innerWidth;
    this.isSmall();

    this.authService.role$.subscribe((role) => {
      this.role = role;
    });

    this.fs.shop$.subscribe((shop: Shop | null): void => {
      if(shop){
        this.shopName = shop.getName;
      }
    });
  }
}
