import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "./shared/page-not-found/page-not-found.component";
import {HomeComponent} from "./components/home/home.component";
import {ShopService} from "@services/shop.service";
import {AuthGuard} from "@services/auth.guard";

const routes: Routes = [
  /** route sans shop defini, les ajouter au tableau dans shopNameService */
  {
    path: '',
    children: []
  },
  /** new shop and account **/
  {
    path: 'new-shop',
    loadChildren: () => import('./new-shop-module/new-shop.module').then((m) => m.NewShopModule)
  },
  /** admin **/
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {
      roles: ['admin', 'reception']
    },
    children: [
      { path: '',
        loadChildren: () => import('./admin-module/admin.module').then((m) => m.AdminModule)
      },
    ]
  },
  /** 404 **/
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor(private router: Router, private shopService: ShopService) {
    this.configureRoutes();
  }

  private configureRoutes() {
    const subDomain = this.shopService.getSubdomain();
    if (subDomain) {
      this.router.resetConfig([
        {
          path: '',
          loadChildren: () => import('./shop-module/shop.module').then(m => m.ShopModule)
        },
        ...this.router.config
      ]);
    } else {
      this.router.resetConfig([
        {
          path: '',
          component: HomeComponent
        },
        ...this.router.config
      ]);
    }
  }
}
