import { NgModule, LOCALE_ID} from '@angular/core';

import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// SharedModule
import {SharedModule} from "./shared/shared.module";

// EXTENSIONS
import './services/date-extensions';

// Angular Fire
import {provideFirebaseApp, initializeApp, getApp} from '@angular/fire/app';
import {
  Firestore,
  initializeFirestore,
  provideFirestore,
  connectFirestoreEmulator,
} from '@angular/fire/firestore';

import {provideAuth, getAuth, Auth} from '@angular/fire/auth';
import {provideFunctions, getFunctions, connectFunctionsEmulator, Functions} from "@angular/fire/functions";
import {connectStorageEmulator, getStorage, provideStorage} from "@angular/fire/storage";

// Services
import { AuthService } from "@services/auth.service";
import { NetworkService } from "@services/network.service"

// Angular date
import {MAT_DATE_LOCALE} from '@angular/material/core';

// Directives
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";


// Component
import {AppComponent} from "./app.component";
import {HomeComponent} from "./components/home/home.component";
import {MenuComponent} from "./components/menu/menu.component";
import {PageNotFoundComponent} from "./shared/page-not-found/page-not-found.component";
import {DialogConfirmComponent} from "./shared/dialog-confirm/dialog-confirm.component";
import {DialogOfflineComponent} from "./components/dialog-offline/dialog-offline.component";
import {DialogLoginComponent} from "./components/dialog-login/dialog-login.component";
import {HeaderComponent} from "./components/header/header.component";
import {BackgroundImgService} from "@services/background-img.service";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
//import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import {ShopService} from "@services/shop.service";
import {ServiceWorkerModule} from "@angular/service-worker";

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    PageNotFoundComponent,
    DialogConfirmComponent,
    DialogOfflineComponent,
    DialogLoginComponent,
    HeaderComponent,
  ],
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ServiceWorkerModule.register('custom-sw.js')
  ],
  providers: [
    AuthService,
    NetworkService,
    ShopService,
    BackgroundImgService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr' },
    DatePipe,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      let firestore: Firestore;
      if (environment.useEmulators) {
        firestore = initializeFirestore(getApp(), {
          experimentalForceLongPolling: true, // Long polling required for Cypress => emulator
          ignoreUndefinedProperties: true,
        });
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      }
      else {
        firestore = initializeFirestore(getApp(), {
          ignoreUndefinedProperties: true,
        });
      }
      return firestore;
    }),
    provideAuth(() => {
      const auth: Auth = getAuth();
      /*
         if(environment.useEmulators){
           connectAuthEmulator(auth, 'http://localhost:9099', {
             disableWarnings: true
           });
         } */

      //provideAppCheck(() => {
      // TODO get a reCAPTCHA Enterprise here https://console.cloud.google.com/security/recaptcha?project=_
      //const provider = new ReCaptchaEnterpriseProvider(/* reCAPTCHA Enterprise site key */);
      //return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
      return auth;
    }),
    provideFunctions(() => {
      let functions: Functions;
      functions = getFunctions(getApp(), 'europe-west3');
      if (environment.useEmulators) {
        connectFunctionsEmulator(functions, '127.0.0.1', 5001);
      }
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, '127.0.0.1', 9199);
      }
      return storage;
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
  ]})
export class AppModule { }
