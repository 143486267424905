<form [formGroup]="loginForm" class="column-container gap10px">
  <h2 mat-dialog-title>Connexion</h2>
  <mat-dialog-content class="column-container gap10px">
    <mat-form-field appearance="outline" *ngIf="shopId">
      <mat-label>Station</mat-label>
      <mat-select formControlName="role" placeholder="CHOISIR ROLE" autocomplete="username">
        <mat-option *ngFor="let role of roles" [value]="role.role">{{ role.display }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="(shopId === '' || shopId === null) || selectedRole === 'admin'">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" type="email" autocomplete="email">
      <mat-error *ngIf="loginForm.get('email')?.errors?.['required']">Email requis</mat-error>
      <mat-error *ngIf="loginForm.get('email')?.errors?.['email']">Email invalide</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="selectedRole != role || (shopId != null) !== (shopId != '')">
      <mat-label>Mot de passe</mat-label>
      <input matInput formControlName="password" type="password" autocomplete="current-password">
      <mat-error *ngIf="loginForm.get('password')?.errors?.['required']">Mot de passe requis</mat-error>
      <mat-error *ngIf="loginForm.get('password')?.errors?.['wrongPassword']">Mot de Passe Erroné</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Annuler</button>
    <button mat-flat-button color="primary" [disabled]="loginForm.invalid" *ngIf="selectedRole != role || !role" (click)="submit()">CONNEXION</button>
    <button mat-button color="warn" (click)="logout()" *ngIf="selectedRole == role && role">DECONNEXION</button>
  </mat-dialog-actions>
</form>
